import {
  createContext,
  createElement,
  Dispatch,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  BusinessStreamName,
  businessStreamSignDownload,
  Result,
  Session,
  SessionCE,
  SessionCI,
  SignatureResponse,
  useRequest,
  useSignedDownload,
} from '../api';
import { useNotification } from '../notifications';
import { useSessions } from '../sessions';
import Axios, { AxiosError } from 'axios';
import { sleep } from '../util/helpers';
import { utcDate, toDateString } from '../components/Calendar';
import { useCentres } from '../centres';
import { isBusinessStreamCI } from '../centres/businessStreamCheck';

export type SignatureContextProps = {
  state: State;
  isDownloadActive: boolean;
  download: (filename: string, isZip?: boolean) => void;
  dispatch: Dispatch<Action>;
  progress: { [filename: string]: number };
};

const SignatureContext = createContext<SignatureContextProps>({
  state: { requests: {}, responses: {} },
  isDownloadActive: false,
  download: () => {
    throw new Error('Missing `SignatureProvider`');
  },
  dispatch: () => {
    throw new Error('Missing `SignatureProvider`');
  },
  progress: {},
});

export const useSignature = (): SignatureContextProps => useContext(SignatureContext);
export const useSignatureItem = (filename: string): SignatureItem => {
  const {
    state: { requests, responses },
  } = useSignature();

  const pending = !!requests[filename];
  const response = responses[filename];
  const started = pending || !!response;
  return useMemo(() => ({ filename, started, pending, ...(response || {}) }), [filename, pending, response, started]);
};

export type SignatureItem = {
  filename: string;
  started: boolean;
  pending: boolean;
  result?: string;
  error?: string;
};
type State = {
  requests: { [filename: string]: { filename: string; isZip: boolean } };
  responses: { [filename: string]: { result?: string; error?: string } };
};

type Action =
  | ['SIGN', string, boolean]
  | ['RESPONSE', string, SignatureResponse]
  | ['REMOVE_REQUEST', string]
  | ['RESET_STATE'];

const reducer = (state: State, action: Action): State => {
  switch (action[0]) {
    case 'SIGN': {
      const [, filename, isZip] = action;
      if (filename in state.requests) return state;
      const response = state.responses[filename];
      const responses = response ? { ...state.responses } : state.responses;
      delete responses[filename];
      return { responses, requests: { ...state.requests, [filename]: { filename, isZip } } };
    }

    case 'RESPONSE': {
      const [, filename, { result, error }] = action;
      const requests = { ...state.requests };
      delete requests[filename];
      return { responses: { ...state.responses, [filename]: { result, error } }, requests };
    }

    case 'REMOVE_REQUEST': {
      const [, filename] = action;
      const requests = { ...state.requests };
      delete requests[filename];
      return { ...state, requests };
    }

    case 'RESET_STATE': {
      return { requests: {}, responses: {} };
    }
  }
};

export const useSessionProduct = (filename: string, businessStream: BusinessStreamName): string => {
  const { data } = useSessions();
  const session =
    data && Object.values(data.items).find((s) => s.download && s.download.find((d) => d.filename === filename));
  return useMemo(() => {
    if (!session) {
      return '';
    }
    if (isBusinessStreamCI(businessStream)) {
      const ciSession = session as SessionCI<'processed'>;
      return [
        ciSession.article?.qualificationShortName,
        ciSession.article?.assessmentId,
        ciSession.article?.componentId,
      ].join(' - ');
    } else {
      return (session as SessionCE<'processed'>).article?.product || '';
    }
  }, [session, businessStream]);
};

const useSessionUniqueIdentifier = (filename: string, businessStream: BusinessStreamName): string => {
  const { data } = useSessions();
  const sessions = [...Object.values(data?.items || []), ...Object.values(data?.childCentreItems || [])];
  const session = sessions.find((s) => s.download && s.download.find((d) => d.filename === filename));

  const articleNo = session?.article.id;
  const date = toDateString(utcDate(session?.date));
  const sitting = (session as Session<'processed'>)?.sitting;
  const series = (session as SessionCI<'processed'>)?.series;
  const startDate =
    (session as SessionCI<'processed'>)?.startDate &&
    toDateString(utcDate((session as SessionCI<'processed'>)?.startDate));

  return useMemo(
    () =>
      session
        ? isBusinessStreamCI(businessStream)
          ? `${articleNo}-${date}-${series}-${sitting || startDate}`
          : `${articleNo}-${date}-${sitting}`
        : '',
    [session, articleNo, date, sitting, series, businessStream, startDate],
  );
};

const SignTask: FC<{
  primaryKey: string;
  filename: string;
  dispatch: Dispatch<Action>;
  businessStream: BusinessStreamName;
  isZip: boolean;
  status: string;
}> = ({ primaryKey, filename, dispatch, businessStream, isZip, status }) => {
  const product = useSessionProduct(filename, businessStream);
  const uniqueIdentifier = useSessionUniqueIdentifier(filename, businessStream);

  const { dispatch: dispatchNotification, messages } = useNotification();
  const request = useSignedDownload(
    useMemo(() => ({ primaryKey, filename, isZip, status }), [primaryKey, filename, isZip, status]),
  );

  useEffect(() => {
    if (request.result) dispatch(['RESPONSE', filename, request.result]);
  }, [dispatch, filename, request]);

  const loadingNotificationId = `notification-${uniqueIdentifier}`;

  useEffect(() => {
    if (request.error) {
      dispatch(['REMOVE_REQUEST', filename]);
      const error = request.error as AxiosError<{ error: string } | null>;

      if (error.response && error.response.data) {
        dispatchNotification(['REMOVE', loadingNotificationId]);
        switch (error.response.data.error) {
          case 'file_not_found':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.FILE_NOT_FOUND },
              'ERROR',
            ]);
            break;

          case 'incorrect_filename':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.INCORRECT_FILENAME },
              'ERROR',
            ]);
            break;

          case 'not_in_download_window':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.NOT_IN_DOWNLOAD_WINDOW },
              'ERROR',
            ]);
            break;

          case 'access_denied':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.ACCESS_DENIED },
              'ERROR',
            ]);
            break;

          default:
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.WATERMARKING_FAILED },
              'ERROR',
            ]);
            break;
        }
      } else {
        dispatchNotification(['REMOVE', loadingNotificationId]);
        dispatchNotification([
          'ADD',
          { title: `${product} failed to download`, body: messages.WATERMARKING_FAILED },
          'ERROR',
        ]);
      }
    }
  }, [dispatch, dispatchNotification, filename, messages, product, request, loadingNotificationId]);

  return null;
};

export const DownloadTask: FC<{
  primaryKey: string;
  filename: string;
  businessStream: BusinessStreamName;
  isZip: boolean;
  updateProgress: (filename: string, value: number) => void;
}> = ({ primaryKey, filename, isZip, businessStream, updateProgress }) => {
  const {
    state: { responses },
  } = useSignature();
  const [status, setStatus] = useState<string>('failed');
  const [downloadInitiated, setDownloadInitiated] = useState<boolean>(false);
  const product = useSessionProduct(filename, businessStream);
  const uniqueIdentifier = useSessionUniqueIdentifier(filename, businessStream);
  const { dispatch, messages } = useNotification();
  const memoizedParams = useMemo(() => ({ primaryKey, filename, isZip, status }), [
    primaryKey,
    filename,
    isZip,
    status,
  ]);
  if (isBusinessStreamCI(businessStream)) {
    useSignedDownload(memoizedParams);
  }
  const response = responses[filename];
  const result = response?.result;

  const { error: linkCheckError, result: linkCheckResult }: Result<string> = useRequest(
    useMemo(() => result && { method: 'GET', url: result, headers: { Range: 'bytes=0-0' } }, [result]),
  );

  const loadingNotificationId = `notification-${uniqueIdentifier}`;

  const fetchData = useCallback(async () => {
    if (!result) {
      console.error('Result is null or undefined');
      setStatus('failed');
      return;
    }

    try {
      const response = await fetch(result);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fileName =
        window
          .decodeURIComponent(result)
          .match(/filename="?([^"]+)"?/)?.[1]
          ?.replace(/^"(.*)"$/, '$1') || 'default-filename';

      const totalSize = Number(response.headers.get('content-length')) || 0;
      let downloadedSize = 0;
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Response body is not readable');
      }

      const chunks: Uint8Array[] = [];
      while (downloadedSize < totalSize) {
        const { done, value } = await reader.read();
        if (done) break;
        if (value) {
          chunks.push(value);
          downloadedSize += value.length;
          const progressPercentage = (downloadedSize / totalSize) * 100;
          updateProgress(filename, progressPercentage); // Update the progress state in context
        }
      }

      const isSuccess = downloadedSize === totalSize;
      setStatus(isSuccess ? 'success' : 'failed');
      businessStreamSignDownload(businessStream, isZip, isSuccess ? 'success' : 'failed');

      const blob = new Blob(chunks, {
        type: response.headers.get('content-type') || 'application/octet-stream',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      setStatus('failed');
    }
  }, [result, businessStream, isZip, updateProgress, filename]);

  useEffect(() => {
    if (isBusinessStreamCI(businessStream) && !downloadInitiated) {
      const handleDownload = async () => {
        await fetchData();
      };
      handleDownload();
      dispatch(['REMOVE', loadingNotificationId]);
      setDownloadInitiated(true);
    } else if (!isBusinessStreamCI(businessStream) && result && linkCheckResult) {
      dispatch(['REMOVE', loadingNotificationId]);
      location.assign(result);
    }
  }, [dispatch, fetchData, result, linkCheckResult, loadingNotificationId, businessStream, downloadInitiated]);

  useEffect(() => {
    if (linkCheckError) {
      dispatch(['REMOVE', loadingNotificationId]);
      dispatch(['ADD', { title: `${product} failed to download`, body: messages.FILE_NOT_FOUND }, 'ERROR']);
    }
  }, [dispatch, linkCheckError, messages, product, loadingNotificationId]);

  return null;
};

export const SignatureProvider: FC = ({ children }) => {
  const sessions = useSessions();
  const { businessStream } = useCentres();
  const [state, dispatch] = useReducer(
    reducer,
    useMemo(() => ({ requests: {}, responses: {} }), []),
  );
  const [progress, setProgress] = useState<{ [filename: string]: number }>({});

  const download = useCallback((filename: string, isZip?: boolean) => {
    dispatch(['SIGN', filename, !!isZip]);
  }, []);

  // Update progress in context
  const updateProgress = useCallback((filename: string, value: number) => {
    setProgress((prevProgress) => ({
      ...prevProgress,
      [filename]: value,
    }));
  }, []);

  const shash = Object.keys(state.requests).join(' ');
  const signinig = useMemo(() => (shash ? shash.split(' ').map((h) => state.requests[h]) : []), [
    shash,
    state.requests,
  ]);

  const dhash = Object.keys(state.responses).join(' ');
  const downloading = useMemo(() => (dhash ? dhash.split(' ') : []), [dhash]);

  const signatures = useMemo(
    () =>
      signinig.map(({ filename, isZip }) =>
        createElement(SignTask, {
          primaryKey: '',
          filename,
          isZip,
          status: 'Pending',
          dispatch,
          businessStream: businessStream,
          key: `sign_${filename}`,
        }),
      ),
    [signinig, businessStream],
  );

  const downloads = useMemo(
    () =>
      downloading.map((filename) =>
        createElement(DownloadTask, {
          primaryKey: '',
          filename,
          businessStream: businessStream,
          isZip: state.requests[filename]?.isZip || false,
          key: `download_${filename}`,
          updateProgress, // Pass updateProgress to DownloadTask
        }),
      ),
    [downloading, businessStream, state.requests, updateProgress],
  );

  const filterDownloadActive = useCallback(
    (session: Session<'processed'>[] | undefined) =>
      session &&
      session
        .filter((item) => item.download && item.download.length)
        .map((item) => {
          const arr = [];
          if (item.download) {
            for (const download of item.download) {
              arr.push(download);
            }
          }
          return arr;
        })
        .flat()
        .map((item) => item?.filename)
        .some((item) => item && !!state.requests[item]),
    [state.requests],
  );

  const isDownloadActive =
    useMemo(
      () =>
        isBusinessStreamCI(businessStream)
          ? filterDownloadActive(sessions?.data?.sortedSessions?.International)
          : filterDownloadActive(sessions?.data?.sortedSessions?.English),
      [sessions, businessStream, filterDownloadActive],
    ) || false;

  const value = useMemo<SignatureContextProps>(() => ({ state, isDownloadActive, download, dispatch, progress }), [
    download,
    state,
    isDownloadActive,
    dispatch,
    progress,
  ]);

  return createElement(SignatureContext.Provider, { value }, children, signatures, downloads);
};

Axios.interceptors.response.use(undefined, async (error: AxiosError | Error) => {
  if (
    'isAxiosError' in error &&
    error.code === 'ECONNABORTED' &&
    (error.config.url?.startsWith('/sign') || error.config.url?.includes('/downloads/signed'))
  ) {
    await sleep(100);
    return Axios.request(error.config);
  }

  return Promise.reject(error);
});
